<template>
	<div class="container">
		<!-- 移动端判定 -->
		<template v-if="isMobile==0">
			<div class="header">
				<span>{{$t('aside.list')[2]}}</span>
			</div>
		</template>
		<!-- 移动端判定 -->
		<div :class="isMobile==0?'content':'content_phone'">
			<div class="switch">
				<el-checkbox v-model="isRun" true-label="1" false-label="0" @change="checkChange">
					{{$t('robot.showRunning')}}</el-checkbox>
			</div>

			<template v-if="robotBoxList.length">
				<div class="robot_list">
					<div class="boxItem" v-for="(list,listIndex) in robotBoxList" :key="listIndex">
						<div class="exchangeTitle">
							<div class="left">
								<div class="li">
									<div class="title">{{$t('common.exchange')}}：</div>
									<div class="text">{{list.web_name}}</div>
								</div>
								<div class="li" v-if="list.web!='opensea'">
									<div class="title">{{$t('common.pair')}}：</div>
									<div class="text">{{list.currency.replace('_',' / ')}}</div>
								</div>
								<div class="li" v-else>
									<div class="title">{{$t('common.opensea')}}：</div>
									<div class="text">{{list.currency}}</div>
								</div>
							</div>
							<div class="right">
								<div class="btn g_btn" @click="jump(list,'/addrobot')">
									{{$t('robot.addrobot')}}
								</div>
								<div class="btn g_btn" @click="jump(list,'Stocks')">
									{{$t('common.handicap')}}
								</div>
							</div>
						</div>
						<div class="itemList">
							<div class="item" v-for="(item,index) in list.robot_list" :key="listIndex+'-'+index"
								:class="{active: activeIndex == listIndex+'-'+index}"
								@click="activeIndex = listIndex+'-'+index">
								<div class="caption" @click="openTip(item.robotType)">
									{{item.robotType?$t('robot.list.'+item.robotType+'.name'):''}}
									<i class="iconfont icon-tips"></i>
								</div>
								<div class="bd">
									<div class="li">
										<div class="title">{{$t('robot.parameter')}}</div>
										<div class="text">
											<robotParamFormat :item="item"></robotParamFormat>
										</div>
									</div>
									<div class="li">
										<div class="title">{{$t('robot.current')}}</div>
										<div class="text">
											<span v-if="item.state==1" class="green">{{$t('robot.running')}}</span>
											<span v-else class="red">{{$t('robot.stopped')}}</span>
										</div>
									</div>
									<!-- 移动端判定 -->
									<div class="li" v-if="isMobile==0">
										<div class="title">{{$t('common.operation')}}</div>
										<div class="text">
											<span class="btn green"
												@click="handleEdit(item,listIndex,index)">{{$t('common.edit')}}</span>
											<span v-if="item.state==1" class="btn red"
												@click="handleStop(item,listIndex,index)">{{$t('common.stop')}}</span>
											<span v-else class="btn green"
												@click="handleStart(item,listIndex,index)">{{$t('common.run')}}</span>
											<span class="btn red"
												@click="handleDelete(item,listIndex,index)">{{$t('common.permanentlyDel')}}</span>
										</div>
									</div>
									<div class="li" v-else>
										<div class="operation">
											<span class="g_btn btn green"
												@click="handleEdit(item,listIndex,index)">{{$t('common.edit')}}</span>
											<span v-if="item.state==1" class="g_btn btn red"
												@click="handleStop(item,listIndex,index)">{{$t('common.stop')}}</span>
											<span v-else class="g_btn btn green"
												@click="handleStart(item,listIndex,index)">{{$t('common.run')}}</span>
											<span class="g_btn btn red"
												@click="handleDelete(item,listIndex,index)">{{$t('common.permanentlyDel')}}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
			<el-empty v-else :image="emptyImg" :image-size="120"
				:description="isRun==0?$t('robot.robotEmpty'):$t('robot.robotEmpty2')">
				<router-link class="g_btn btn" to="/addrobot" v-slot="{ navigate, href }" custom>
					<div @click="navigate" :href="href">
						{{$t('robot.addrobot')}}
					</div>
				</router-link>
			</el-empty>
		</div>

		<!-- 编辑弹窗 -->
		<!-- @close="activeIndex = null" -->
		<el-dialog :title="robotType?$t('common.editTitle')+$t('robot.list.'+robotType+'.name'):''"
			:visible.sync="showEdit" :before-close="beforeClose">
			<robotForm ref="robotForm" :robotType="robotType" @handleCancel="handleCancel"
				@handleConfirm="handleConfirm(arguments)" @openPop="openPop" :yenUnit1Old="yenUnit1"
				:yenUnit2Old="yenUnit2" :web="web"></robotForm>
		</el-dialog>
		<!-- 说明弹窗 -->
		<el-dialog class="w900" :title="robotType?$t('robot.list.'+robotType+'.name')+$t('common.explain'):''"
			:visible.sync="showTips" @close="activeIndex = null">
			<div class="tips_form" v-html="robotType?$t('robot.list.'+robotType+'.html'):''"></div>
		</el-dialog>

		<!-- 机器人关闭倒计时 -->
		<div class="notification" :class="{active:item.countDown>0}" v-for="(item,key,index) in closingRobot"
			:key="index" :style="{top:index==0?70+'px':70+index*90+'px'}">
			<h2 class="notification__title"> {{$t('robot.list.'+item.robotType+'.name')}} </h2>
			<div class="notification__content">
				<p>
					{{$t('tip.closing',{time:item.countDown})}}
				</p>
			</div>
		</div>

		<!-- 交易所选择 -->
		<exchange-dialog :show.sync="popExchange" :web="web" @close="closeExchange" @select="handleSelect" />

		<!-- 分拨资金弹窗 -->
		<subTransfer ref="subTransfer" :address_list="address_list" :id_list="id_list" :chain="chain" :token="token"
			:approve_token="approve_token" :web="transferWeb" :exchange="transferExchange"></subTransfer>

		<!-- 报警弹窗 -->
		<alarmDialog ref="alarmDialog"></alarmDialog>
	</div>
</template>

<style scoped="scoped" lang="stylus">
	@import 'robot.styl';
	@import 'index.styl';
</style>

<script>
	import Decimal from 'decimal.js'
	import { Loading } from 'element-ui';

	import ExchangeDialog from '@/components/exchange.vue';
	import robotForm from '@/components/robotForm.vue';
	import subTransfer from '@/components/subTransfer/subTransfer.vue';
	import robotParamFormat from '@/components/robotParamFormat.vue';
	import alarmDialog from "@/components/alarm/alarmDialog.vue";

	import { mapGetters } from 'vuex';
	import typeFormat from '@/utils/methApi/typeFormat';
	import robotNameFormat from '@/utils/methApi/robotNameFormat';
	import scientificNotationFormat from '@/utils/methApi/scientificNotationFormat'

	// api
	import { getRobotList, setRobot, delRobot } from '@/api/robot';
	import { dexWalletsApprove, getPankou } from '@/api/markets';
	export default ({
		name: 'account',
		components: {
			ExchangeDialog,
			robotForm,
			subTransfer,
			robotParamFormat,
    		alarmDialog,
		},
		data() {
			return {
				showTips: false,
				showEdit: false,
				emptyImg: require('@/assets/images/loading.png'),
				activeIndex: null,
				robotType: '',
				isRun: localStorage.getItem('light_is_run') || '0',
				closingRobot: {},//存放正在关闭倒数的机器人，及倒计时

				// 此处数据仅用于测试,请自行设置数据名称
				robotBoxList: [],
				// 弹窗1
				exchange: '',
				yenUnit1: '',
				yenUnit2: '',
				// 交易所选择
				popExchange: false,
				web: null,
				// 子组件的交易所
				isSonPop: false,
				web2: null,
				sonFormNum: 0,

				// 资金划分
				address_list: [],
				id_list: [],
				chain: '',
				token: '',
				approve_token: '',
				transferWeb: '',
				transferExchange: '',

				restartLoading: null,//机器人是否正在重启
				resetCountdownTime: 10,//重启倒计时

				loadingSerive: null,
				average: '',// 盘口买入卖出中间值
			}
		},
		computed: {
			...mapGetters(['account', 'isSmall', 'isMobile']),
		},
		created() {
			this.getList();
		},
		methods: {
			// 仅显示运行勾选监听
			checkChange() {
				localStorage.setItem('light_is_run', this.isRun)
				this.getList()
			},

			jump(list, path) {
				// console.log(list)
				var arr = list.currency.split('_')
				// 盘口信息
				let dataObj = {
					exchange: list.web_name,
					web: list.web,
					yenUnit1: arr[0],
					yenUnit2: arr[1],
					web_id: list.web_id,
					web_type: list.web_type,
				}
				if (path == 'Stocks') {
					// 跳转手动操盘 进入盘口
					this.$router.push({
						name: path,
						params: dataObj
					})
				} else if (path == '/addrobot') {
					// 跳转添加机器人
					this.$router.push({
						path,
						query: dataObj
					})
				}
			},
			// 获取机器人列表
			getList() {
				this.loadingSerive = Loading.service({
					target: '.main',
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
					customClass: 'apploading',
				});
				getRobotList({
					account: this.account,
					state: this.isRun,
				}).then(res => {
					// console.log(res)

					// 狙击新盘-snipe_dex 下架
					var robotBoxList = []
					if (!!res) {
						res.forEach(li => {
							// if(!!li.robot_list){
							// 	li.robot_list.forEach(item=>{
							// 		item.robotType=robotNameFormat(item.type,item.sub_type)
							// 	})
							// }
							var pankouInfo = JSON.parse(JSON.stringify(li))
							if (!!li.robot_list) {
								var robot_list = []
								li.robot_list.forEach(item => {
									item.robotType = robotNameFormat(item.type, item.sub_type)
									if (item.robotType != 'snipe_dex') {
										robot_list.push(item)
									}
								})
							}
							if (robot_list.length > 0) {
								pankouInfo.robot_list = robot_list
								robotBoxList.push(pankouInfo)
							}
						})
					}
					// this.robotBoxList=res
					this.robotBoxList = robotBoxList

					this.loadingSerive.close();
				}).catch(err => {
					this.loadingSerive.close();
				})
			},
			// 打开提示弹窗
			openTip(robotType) {
				this.robotType = robotType;
				this.showTips = true;
			},
			// 编辑
			handleEdit(item, listIndex, index) {
				// console.log('item',item)
				// 选中项
				this.activeIndex = listIndex + '-' + index;
				// 机器人对应项
				this.robotType = item.robotType;

				var currency = item.currency.split('_')

				this.web = item.web;
				this.exchange = item.web;
				this.yenUnit1 = currency[0];
				this.yenUnit2 = currency[1];
				// 弹窗
				this.showEdit = true;
				// 重新获取参数-传递param给组件 getRobot
				this.$nextTick(() => {
					if (this.$refs.robotForm) {
						if (
							(this.robotType == 'push' && item.sub_type == 'asks') ||
							(this.robotType == 'real_genpan' && item.sub_type == 'rebalance')
						) {
							// 传入默认单选项
							this.$refs.robotForm.paramInit(item.state,item.param, 2);
						} else {
							this.$refs.robotForm.paramInit(item.state,item.param);
						}
					}
				});
			},
			beforeClose(done) {
				this.activeIndex = null;
				done()
			},
			// 弹窗
			handleCancel() {
				this.showEdit = false;
			},
			handleConfirm(data) {
				var param = data[0];
				var subTypeSelect = data[1];
				var isRestart = data[2];
				var groupId = data[3];
				// console.log('param',param,subTypeSelect)

				var { type, sub_type } = typeFormat(this.robotType, subTypeSelect)

				var currency;
				if (this.web != 'opensea') {
					currency = this.yenUnit1 + '_' + this.yenUnit2
				} else {
					currency = this.yenUnit1
				}
				this.setRobot(param, type, sub_type, currency, isRestart, groupId)
			},
			// 修改机器人参数请求
			setRobot(param, type, sub_type, currency, isRestart, groupId, approve_type) {
				// 如果保存并启动，则判断开启的机器人是否在关闭倒计时机器人中
				if ( this.closingRobot[this.web+'_'+currency+'_'+this.robotType] ) {
					// 正在关闭中，请稍后..
					this.$message({
						message: this.$t('tip.closingErr'),
						center: true,
						type: 'error',
						customClass: 'shotMsg',
					});
				} else {
					let state = 1

					// 判断是否是需要重启,如果不是直接修改参数
					if (isRestart == 1) {
						// 需要重启，先设置停止机器人并保存
						state = 0
						// 触发重启过程中强制禁止操作方法
						this.restartLoading = Loading.service({
							text: this.$t('tip.restart', { time: this.resetCountdownTime ? '(' + this.resetCountdownTime + ')' : '' }),
							spinner: 'el-icon-loading',
							background: 'rgba(0, 0, 0, 0.7)',
							customClass: 'apploading',
						});
					}

					setRobot({
						web: this.web,
						currency,
						account: this.account,
						type: type,
						sub_type: sub_type,
						param: param,
						state,
						approve_type: approve_type == 'again' ? '0' : '1'
					}).then(res => {
						if (res.code == 2107 || (res.code == 2109 && res.type == 'now')) {
							// 未授权 提示授权中。。
							this.$message({
								message: this.$t('tip.processing'),
								type: 'success',
								center: true,
								customClass: 'shotMsg',
							});
							// 授权代币请求
							dexWalletsApprove({
								web: this.web,
								account: this.account,
								approve_token: res.data.replace(',', '_'),//代币
								is_master_approve: 2,
                        		group_id: groupId,
							}).then(re => {
								if (re.code == 2172 && re.type == 'confirm') {
									// 分拨授权
									this.address_list = re.data.address_list
									this.id_list = re.data.id_list
									this.chain = re.data.chain
									this.token = re.data.gas_token
									this.approve_token = re.data.approve_token
									this.transferWeb = this.web
									this.transferExchange = this.exchange
									this.$refs.subTransfer.open()
								} else if (re.code == 2172 && re.type == 'ignore') {
									// 忽略，重新操作
									this.setRobot(param, type, sub_type, currency, isRestart, groupId, 'again')
								} else {
									// 授权成功
									this.$confirm(this.$t('tip.authorizeSuc'), this.$t('tip.tips'), {
										confirmButtonText: this.$t('common.confirm'),//'继续操作',
										// showConfirmButton:false,
										center: true,
										// showClose: false,
										customClass: 'deleteOk',
										// cancelButtonText: this.$t('common.back'),
										showCancelButton: false,
									})
								}
							})
						} else if (res.code == 2109 && res.type == 'ignore') {
							// 忽略，重新操作
							this.setRobot(param, type, sub_type, currency, isRestart, groupId, 'again')
						} else if (res.code == 2109 && res.type == 'confirm') {
							// 分拨授权
							this.address_list = res.data.address_list
							this.id_list = res.data.id_list
							this.chain = res.data.chain
							this.token = res.data.gas_token
							this.approve_token = res.data.approve_token
							this.transferWeb = this.web
							this.transferExchange = this.exchange
							this.$refs.subTransfer.open()
						} else {
							// 判断是否是需要重启
							if (isRestart == 1) {
								this.resetCountdownTime = 10
								// 10s后重启-倒计时
								let resetCountdown = setInterval(() => {
									if (this.resetCountdownTime > 0) {
										this.resetCountdownTime--
										this.restartLoading.setText(this.$t('tip.restart', { time: this.resetCountdownTime ? '(' + this.resetCountdownTime + ')' : '' }));
									} else {
										clearTimeout(resetCountdown)
									}
								}, 1000)
								// 10s后重启
								let robotTimeReset = setTimeout(() => {
									this.setRobot(param, type, sub_type, currency, 0, groupId)
								}, 10000)
								this.$once('hook:beforeDestroy', function () {
									clearInterval(resetCountdown)
									clearTimeout(robotTimeReset)
								});
							} else {
								// 不需要重启或者是重启已完成，关闭重启过程中强制禁止操作方法，
								this.restartLoading?.close();

								// 并初始化
								this.getList()
								// 保存--成功
								this.$message({
									message: this.$t('tip.saveSuc'),
									center: true,
									type: 'success',
									customClass: 'shotMsg',
								});
								this.showEdit = false;
								this.activeIndex = null;
								
								// 启动机器人则二次确认是否需要配置报警系统
								if(state==1){
									this.$refs.alarmDialog?.openCheckTip(1, {
										web:this.web,
										yenUnit1:this.yenUnit1,
										yenUnit2:this.yenUnit2,
									})
								}
							}
						}
					})
				}
			},

			// 获取盘口数据
			async getPankou(web, currency) {
				this.average = 0//初始化

				this.loadingSerive = Loading.service({
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
					customClass: 'apploading',
				})
				try {
					let res = await getPankou({
						web,
						account: this.account,
						currency,
					})
					this.loadingSerive.close()
					if (!res) {
						this.average = 0
					} else {
						var sellPrice = !res.bids || !res.bids.length ? 0 : res.bids[0].price;
						var buyPrice = !res.asks || !res.asks.length ? 0 : res.asks[0].price;
						this.average = (new Decimal(sellPrice).add(new Decimal(buyPrice))).div(new Decimal(2)).toString()
					}
				} catch (error) {
					this.average = 0
					this.loadingSerive.close()
				}
			},
			// 科学计数法格式化
			scientificNotationFormat(num) {
				return scientificNotationFormat(num)
			},
			async handleStart(item, listIndex, index, approve_type) {
				// console.log(item)
				this.activeIndex = listIndex + '-' + index;
				try {
					if (item.robotType == 'budan_new') {
						// 盘口管理机器人
						await this.getPankou(item.web, item.currency)
						if (this.average == 0) {
							// 无法读取盘口数据机器人无法正常工作
							await this.$confirm(this.$t('robot.pankouReadFail'), this.$t('tip.tips'), {
								confirmButtonText: this.$t('common.confirm2'),
								center: true,
								// showClose: false,
								customClass: 'deleteOk',
								showCancelButton: false,
								cancelButtonText: this.$t('common.back'),
							})
							return
						} else {
							// 买一：中间价 - 中间价*盘口差价/2
							// 卖一：中间价 + 中间价*盘口差价/2
							var paramArr = item.param.split(' ')
							var halfPankou = new Decimal(this.average).mul(new Decimal(paramArr[2])).div(new Decimal(200))
							var buyPriceTarget = new Decimal(this.average).minus(halfPankou).toString()
							var sellPriceTarget = new Decimal(this.average).add(halfPankou).toString()

							buyPriceTarget = Number(buyPriceTarget)
							sellPriceTarget = Number(sellPriceTarget)

							// 去除科学计数法
							buyPriceTarget = this.scientificNotationFormat(buyPriceTarget)
							sellPriceTarget = this.scientificNotationFormat(sellPriceTarget)

							await this.$confirm(this.$t('robot.positionTip', { buyPriceTarget, sellPriceTarget }), this.$t('tip.tips'), {
								confirmButtonText: this.$t('common.confirm2'),
								center: true,
								// showClose: false,
								customClass: 'deleteOk',
								cancelButtonText: this.$t('common.back'),
								dangerouslyUseHTMLString: true,
							})
						}
					} else {
						await this.$confirm(this.$t('tip.startRobot'), this.$t('tip.tips'), {
							confirmButtonText: this.$t('common.confirm2'),
							cancelButtonText: this.$t('common.back'),
							center: true,
							// showClose: false,
							customClass: 'deleteOk',
						})
					}

					this.activeIndex = null;
					var data = Object.assign({}, item)
					data.state = 1;
					data.approve_type = approve_type == 'again' ? '0' : '1'
					if (this.closingRobot[item.web+'_'+item.currency+'_'+item.robotType]) {
						// 正在关闭中，请稍后..
						this.$message({
							message: this.$t('tip.closingErr'),
							center: true,
							type: 'error',
							customClass: 'shotMsg',
						});
					} else {
						setRobot(data).then(res => {
							if (res.code == 2107 || (res.code == 2109 && res.type == 'now')) {
								// 未授权 提示授权中。。
								this.$message({
									message: this.$t('tip.processing'),
									type: 'success',
									center: true,
									customClass: 'shotMsg',
								});
								// 授权代币请求
								dexWalletsApprove({
									web: data.web,
									account: this.account,
									approve_token: res.data.replace(',', '_'),//代币
									is_master_approve: 2,
                        			group_id: '-999',
								}).then(re => {
									if (re.code == 2172 && re.type == 'confirm') {
										// 分拨授权
										this.address_list = re.data.address_list
										this.id_list = re.data.id_list
										this.chain = re.data.chain
										this.token = re.data.gas_token
										this.approve_token = re.data.approve_token
										this.transferWeb = this.web
										this.transferExchange = this.exchange
										this.$refs.subTransfer.open()
									} else if (re.code == 2172 && re.type == 'ignore') {
										// 忽略，重新操作
										this.handleStart(item, listIndex, index, 'again')
									} else {
										// 授权成功
										this.$confirm(this.$t('tip.authorizeSuc'), this.$t('tip.tips'), {
											confirmButtonText: this.$t('common.confirm'),//'继续操作',
											// showConfirmButton:false,
											center: true,
											// showClose: false,
											customClass: 'deleteOk',
											// cancelButtonText: this.$t('common.back'),
											showCancelButton: false,
										})
									}
								})
							} else if (res.code == 2109 && res.type == 'ignore') {
								// 忽略，重新操作
								this.handleStart(item, listIndex, index, 'again')
							} else if (res.code == 2109 && res.type == 'confirm') {
								// 分拨授权
								this.address_list = res.data.address_list
								this.id_list = res.data.id_list
								this.chain = res.data.chain
								this.token = res.data.gas_token
								this.approve_token = res.data.approve_token
								this.transferWeb = this.web
								this.transferExchange = this.exchange
								this.$refs.subTransfer.open()
							} else {
								this.getList()
								// 启动--成功
								this.$message({
									message: this.$t('tip.startSuc'),
									center: true,
									type: 'success',
									customClass: 'shotMsg',
								});
								
								// 启动机器人则二次确认是否需要配置报警系统
								this.$refs.alarmDialog?.openCheckTip(1, {
									web:data.web,
									yenUnit1:data.currency.split('_')[0]||'',
									yenUnit2:data.currency.split('_')[1]||'',
								})
							}
						})
					}
				} catch (error) {
					// 取消
					this.activeIndex = null;
				}
			},
			handleStop(item, listIndex, index, approve_type) {
				this.activeIndex = listIndex + '-' + index;
				this.$confirm(this.$t('tip.stopRobot'), this.$t('tip.tips'), {
					confirmButtonText: this.$t('common.confirm2'),
					cancelButtonText: this.$t('common.back'),
					center: true,
					// showClose: false,
					customClass: 'deleteOk',
				}).then(() => {
					this.activeIndex = null;
					var data = Object.assign({}, item)
					data.state = 0;
					data.approve_type = approve_type == 'again' ? '0' : '1'

					setRobot(data).then(res => {
						if (res.code == 2107 || (res.code == 2109 && res.type == 'now')) {
							// 未授权 提示授权中。。
							this.$message({
								message: this.$t('tip.processing'),
								type: 'success',
								center: true,
								customClass: 'shotMsg',
							});
							// 授权代币请求
							dexWalletsApprove({
								web: data.web,
								account: this.account,
								approve_token: res.data.replace(',', '_'),//代币
								is_master_approve: 2,
                        		group_id: '-999',
							}).then(re => {
								if (re.code == 2172 && re.type == 'confirm') {
									// 分拨授权
									this.address_list = re.data.address_list
									this.id_list = re.data.id_list
									this.chain = re.data.chain
									this.token = re.data.gas_token
									this.approve_token = re.data.approve_token
									this.transferWeb = this.web
									this.transferExchange = this.exchange
									this.$refs.subTransfer.open()
								} else if (re.code == 2172 && re.type == 'ignore') {
									// 忽略，重新操作
									this.handleStop(item, listIndex, index, 'again')
								} else {
									// 授权成功
									this.$confirm(this.$t('tip.authorizeSuc'), this.$t('tip.tips'), {
										confirmButtonText: this.$t('common.confirm'),//'继续操作',
										// showConfirmButton:false,
										center: true,
										// showClose: false,
										customClass: 'deleteOk',
										// cancelButtonText: this.$t('common.back'),
										showCancelButton: false,
									})
								}
							})
						} else if (res.code == 2109 && res.type == 'ignore') {
							// 忽略，重新操作
							this.handleStop(item, listIndex, index, 'again')
						} else if (res.code == 2109 && res.type == 'confirm') {
							// 分拨授权
							this.address_list = res.data.address_list
							this.id_list = res.data.id_list
							this.chain = res.data.chain
							this.token = res.data.gas_token
							this.approve_token = res.data.approve_token
							this.transferWeb = this.web
							this.transferExchange = this.exchange
							this.$refs.subTransfer.open()
						} else {
							this.getList()
							// 停止--成功
							this.$message({
								message: this.$t('tip.stopSuc'),
								center: true,
								type: 'success',
								customClass: 'shotMsg',
							});
							// 如果停止成功 则提示10S倒计时自动关闭，并加入closingRobot
							var keyName = item.web+'_'+item.currency+'_'+item.robotType
							console.log(keyName)
							this.$set(this.closingRobot, keyName, {
								countDown: 10,
								robotType: item.robotType
							})
							var timeR = setInterval(() => {
								var countDown = this.closingRobot[keyName].countDown
								if (countDown > 0) {
									var time = countDown - 1
									this.$set(this.closingRobot[keyName], 'countDown', time)
								} else {
                    				this.$delete(this.closingRobot, keyName);
									clearInterval(timeR)
								}
							}, 1000)
						}
					})
				}).catch(() => {
					// 取消
					this.activeIndex = null;
				});
			},
			handleDelete(item, listIndex, index) {
				this.activeIndex = listIndex + '-' + index;
				this.$confirm(this.$t('tip.delTip'), this.$t('tip.tips'), {
					confirmButtonText: this.$t('common.confirm2'),
					cancelButtonText: this.$t('common.back'),
					center: true,
					// showClose: false,
					customClass: 'deleteOk',
				}).then(() => {
					this.activeIndex = null;
					delRobot({
						web: item.web,
						currency: item.currency,
						account: item.account,
						robot_id: item.id
					}).then(res => {
						this.getList()

						// 删除--成功
						this.$message({
							message: this.$t('tip.delSuc'),
							center: true,
							type: 'success',
							customClass: 'shotMsg',
						});
					})
				}).catch(() => {
					// 取消
					this.activeIndex = null;
				});
			},

			// 交易所
			closeExchange() {
				this.popExchange = false;
				// 子组件判定初始化
				if (this.isSonPop) {
					this.isSonPop = false;
					// 还原交易所数据
					this.web = this.web2;
					this.web2 = null;
				}
			},
			handleSelect(data) {
				if (this.isSonPop) {
					if (this.sonFormNum == 'web') {
						// 主交易所
						this.exchange = data.text;
						this.web = data.web;
						// 子组件判定初始化
						this.isSonPop = false;
						this.popExchange = false;
					} else {
						this.$refs.robotForm.handleSelect(data, this.sonFormNum)
						// 子组件判定初始化
						this.isSonPop = false;
						this.popExchange = false;
						// 还原交易所数据
						this.web = this.web2;
						this.web2 = null;
					}
				} else {
					this.exchange = data.text;
					this.web = data.web;
					this.popExchange = false;
				}
			},
			// 子组件交易所
			openPop(data) {
				// 暂存原交易所数据
				this.web2 = this.web;
				// 子组件交易所数据展示
				this.web = data.web;

				this.isSonPop = true;
				this.popExchange = true;
				if (data.num) {
					this.sonFormNum = data.num;
				}
			},
		}
	})
</script>